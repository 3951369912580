import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

function Entrenadores() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [currentCoach, setCurrentCoach] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const coaches = [
    {
      name: "Marcos Rivera",
      position: "Entrenador Jefe",
      description:
        "Ex atleta de alto rendimiento con más de 8 años de experiencia como entrenador personal y grupal en entrenamientos funcionales, adaptados para todas las edades.",
      image: "/images/marcos.JPG",
    },
    {
      name: "Alejandra Santamaria",
      position: "Entrenadora",
      description:
        "Con más de 14 años de experiencia en diferentes deportes competitivos, graduada de entrenadora personal y gestión de centros fitness en Guatemala.",
      image: "/images/ale.JPG",
    },
    {
      name: "Jimmy Aguilar",
      position: "Entrenador",
      description:
        "Maestro de boxeo funcional con años de experiencia. Jimmy se enfoca en mejorar la fuerza, resistencia y coordinación. Dirige clases dinámicas para grupos de todos los niveles, adaptando entrenamientos para maximizar resultados.",
      image: "/images/jimmy.JPG",
    },
    {
      name: "Jorge Flores",
      position: "Entrenador",
      description:
        "Atleta con más de 4 años de experiencia en powerlifting y crossfit, especializado en guiar a las personas hacia su máximo rendimiento y en ayudarlas a alcanzar la mejor versión de sí mismas a través de un entrenamiento integral y eficaz.",
      image: "/images/jorge.JPG",
    },
  ];

  const handlePrev = () => {
    setCurrentCoach((prev) =>
      prev === 0 ? coaches.length - 1 : prev - 1
    );
  };

  const handleNext = () => {
    setCurrentCoach((prev) =>
      prev === coaches.length - 1 ? 0 : prev + 1
    );
  };

  const styles = {
    entrenadoresSection: {
      padding: "50px",
      backgroundImage: "url('/images/headerbackground.jpg')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      color: "#ffffff",
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: "30px",
      textAlign: "center",
    },
    coachContainer: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: "center",
      justifyContent: "center",
      gap: "20px",
    },
    coachImage: {
      width: isMobile ? "80%" : "300px",
      height: "auto",
      borderRadius: "15px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
    },
    coachDetails: {
      textAlign: "left",
      maxWidth: "400px",
    },
    coachName: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "1.8rem",
      fontWeight: "bold",
      marginBottom: "10px",
      color: "#ffffff",
    },
    coachPosition: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1.2rem",
      fontWeight: "bold",
      color: "#1466C3",
      marginBottom: "15px",
    },
    coachDescription: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.5",
      color: "#ffffff",
    },
    navButtons: {
      display: "flex",
      justifyContent: "center",
      gap: "20px",
      marginTop: "30px",
    },
    button: {
      backgroundColor: "#1466C3",
      color: "#ffffff",
      border: "none",
      padding: "10px 20px",
      borderRadius: "8px",
      fontSize: "2rem",
      fontFamily: "Montserrat, sans-serif",
      fontWeight: "bold",
      cursor: "pointer",
      transition: "all 0.3s ease",
    },
    buttonHover: {
      backgroundColor: "#0f4a8d",
      transform: "scale(1.1)",
    },
  };

  return (
    <section id="entrenadores" style={styles.entrenadoresSection}>
      <h2 style={styles.title}>Entrenadores</h2>
      <AnimatePresence>
        <motion.div
          key={currentCoach}
          initial={{ opacity: 0, x: 500 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -500 }}
          transition={{ duration: 1 }}
          style={styles.coachContainer}
        >
          <img
            src={coaches[currentCoach].image}
            alt={coaches[currentCoach].name}
            style={styles.coachImage}
          />
          <div style={styles.coachDetails}>
            <h3 style={styles.coachName}>{coaches[currentCoach].name}</h3>
            <p style={styles.coachPosition}>
              {coaches[currentCoach].position}
            </p>
            <p style={styles.coachDescription}>
              {coaches[currentCoach].description}
            </p>
          </div>
        </motion.div>
      </AnimatePresence>
      <div style={styles.navButtons}>
        <motion.button
          whileHover={{ backgroundColor: "#0f4a8d", scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          style={{
            ...styles.button,
            position: "relative", // Ensure it doesn't affect layout
            transformOrigin: "center",
          }}
          onClick={handlePrev}
        >
          &larr;
        </motion.button>
        <motion.button
          whileHover={{ backgroundColor: "#0f4a8d", scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          style={{
            ...styles.button,
            position: "relative", // Ensure it doesn't affect layout
            transformOrigin: "center",
          }}
          onClick={handleNext}
        >
          &rarr;
        </motion.button>
      </div>
    </section>
  );
}

export default Entrenadores;


