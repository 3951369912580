import React, { useState, useEffect } from "react";

function Membresias() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isRecurring, setIsRecurring] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const togglePaymentMode = () => setIsRecurring(!isRecurring);

  const memberships = [
    {
      title: "Elite",
      details: [
        "Acceso ilimitado a todas las clases grupales.",
        "Válido en cualquier horario.",
        "Acceso al seguimiento de datos biométricos.",
        "Descuento con pago recurrente cada mes (Renovación automática).",
      ],
      recurringPrice: "$145 al mes",
      oneTimePrice: "$155",
      recurringLink: "https://pay.n1co.shop/pl/m9E8YfLWV",
      oneTimeLink: "https://pay.n1co.shop/pl/pKBpGt2PL",
    },
    {
      title: "Athlete",
      details: [
        "12 clases válidas por un mes.",
        "Válido en cualquier horario.",
        "Descuento con pago recurrente cada mes (Renovación automática).",
      ],
      recurringPrice: "$110 al mes",
      oneTimePrice: "$120",
      recurringLink: "https://pay.n1co.shop/pl/X5wK1CODV",
      oneTimeLink: "https://pay.n1co.shop/pl/B57j4T8Z3",
    },
    {
      title: "Casual",
      details: [
        "8 clases válidas por un mes.",
        "Válido en cualquier horario.",
        "Descuento con pago recurrente cada mes (Renovación automática).",
      ],
      recurringPrice: "$80 al mes",
      oneTimePrice: "$85",
      recurringLink: "https://pay.n1co.shop/pl/wWXbpFDPy",
      oneTimeLink: "https://pay.n1co.shop/pl/B57MOS8Z3",
    },
    {
      title: "4 Classes",
      details: [
        "4 clases válidas por 4 semanas.",
        "Válido en cualquier horario.",
      ],
      price: "$45",
      link: "https://pay.n1co.shop/pl/8K1LxUOxj",
    },
    {
      title: "One Class",
      details: [
        "1 clase válida por 4 semanas.",
        "Válido en cualquier horario.",
      ],
      price: "$12",
      link: "https://pay.n1co.shop/pl/pK29AS2PL",
    },
  ];

  const styles = {
    membershipsSection: {
      padding: "100px 20px 50px 20px",
      backgroundImage: "url('/images/bluegirl.jpg')",
      backgroundSize: "cover",
      backgroundPosition: isMobile ? "80% center" : "center",
      backgroundRepeat: "no-repeat",
      textAlign: "center",
      color: "#ffffff",
    },
    commonExplanations: {
      maxWidth: "800px",
      margin: "0 auto 40px auto",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.5",
      color: "#ffffff",
    },
    membershipsContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "20px",
      maxWidth: "1200px",
      margin: "0 auto",
    },
    membershipCard: {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      borderRadius: "10px",
      padding: "20px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
      textAlign: "center",
      flex: "1 1 300px",
      maxWidth: "400px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: "400px",
    },
    membershipTitle: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    toggleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      marginBottom: "20px",
    },
    toggleText: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#ffffff",
    },
    toggleButton: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "120px",
      height: "40px",
      backgroundColor: isRecurring ? "#1466C3" : "#cccccc",
      borderRadius: "20px",
      padding: "5px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },
    toggleCircle: {
      position: "absolute",
      top: "5px",
      left: isRecurring ? "5px" : "65px",
      width: "30px",
      height: "30px",
      backgroundColor: "#ffffff",
      borderRadius: "50%",
      transition: "left 0.3s ease",
    },
    bulletList: {
      fontFamily: "Montserrat, sans-serif",
      textAlign: "left",
      listStyleType: "disc",
      paddingLeft: "20px",
      fontSize: "1rem",
      lineHeight: "1.5",
    },
    price: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginTop: "10px",
      marginBottom: "20px",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      backgroundColor: "#1466C3",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: "#ffffff",
      border: "none",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
    },
    buttonHover: {
      backgroundColor: "#0d4e91",
      transform: "scale(1.05)",
    },
  };

  return (
    <section style={styles.membershipsSection}>
      <h2 style={{ fontFamily: "Gendy, sans-serif", fontSize: "2.5rem" }}>
        Membresías
      </h2>

      <div style={styles.commonExplanations}>
        <p>
          Todas nuestras membresías incluyen acceso a nuestra app, resúmenes de
          rendimiento enviados a tu correo y la posibilidad de participar en
          los desafíos de FitInstinct.
        </p>
      </div>

      <div style={styles.membershipsContainer}>
        {memberships.map((membership, index) => (
          <div key={index} style={styles.membershipCard}>
            <h3 style={styles.membershipTitle}>{membership.title}</h3>
            <ul style={styles.bulletList}>
              {membership.details.map((detail, idx) => (
                <li key={idx}>{detail}</li>
              ))}
            </ul>
            {membership.recurringPrice && membership.oneTimePrice && (
              <div style={styles.toggleContainer}>
                <span style={styles.toggleText}>Renovación automática</span>
                <div style={styles.toggleButton} onClick={togglePaymentMode}>
                  <div style={styles.toggleCircle}></div>
                </div>
                <span style={styles.toggleText}>Pago único</span>
              </div>
            )}
            <p style={styles.price}>
              {membership.price ||
                (isRecurring
                  ? membership.recurringPrice
                  : membership.oneTimePrice)}
            </p>
            <button
              style={styles.button}
              onClick={() =>
                (window.location.href =
                  membership.link ||
                  (isRecurring
                    ? membership.recurringLink
                    : membership.oneTimeLink))
              }
            >
              Comprar
            </button>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Membresias;