import React from "react";
import { useNavigate } from "react-router-dom";

function EntrenamientoEspecializado() {
  const navigate = useNavigate();

  const styles = {
    section: {
      padding: "50px",
      backgroundImage: "url('/images/especializado.JPG')", // Background image path
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      color: "#ffffff", // White text for readability
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      minHeight: "100vh",
    },
    textBox: {
      backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent black box
      padding: "30px",
      borderRadius: "10px",
      maxWidth: "400px",
      textAlign: "left",
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: "20px",
    },
    description: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.8",
      marginBottom: "20px",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      backgroundColor: "#1466C3",
      color: "#ffffff",
      border: "none",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: "pointer",
      textTransform: "uppercase",
      transition: "all 0.3s ease-in-out", // Smooth transition for hover effect
    },
    buttonHover: {
      backgroundColor: "#0d4e91", // Slightly darker blue
      transform: "scale(1.05)", // Slight enlargement
    },
  };

  const handleMouseOver = (e) => {
    e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
    e.currentTarget.style.transform = styles.buttonHover.transform;
  };

  const handleMouseOut = (e) => {
    e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
    e.currentTarget.style.transform = "scale(1)";
  };

  return (
    <section id="entrenamiento-especializado" style={styles.section}>
      <div style={styles.textBox}>
        <h2 style={styles.title}>Entrenamiento Especializado</h2>
        <p style={styles.description}>
          Descubre nuestras soluciones personalizadas: entrenamiento personal
          para cualquier persona, entrenamiento corporativo en oficinas y
          entrenamiento para equipos de alto rendimiento y atletas. Diseñados
          para maximizar el rendimiento y adaptarse a tus necesidades.
        </p>
        <button
          style={styles.button}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={() => navigate("/informacion-especializado")}
        >
          Más Información
        </button>
      </div>
    </section>
  );
}

export default EntrenamientoEspecializado;