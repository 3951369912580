import React, { useState, useEffect } from "react";

function About() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    aboutSection: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row", // Stack vertically for mobile
      justifyContent: isMobile ? "center" : "flex-start", // Align left for desktop
      alignItems: "center",
      padding: isMobile ? "0" : "40px 20px", // Remove padding for mobile
      backgroundImage: isMobile ? "none" : "url('/images/hermanos2.JPG')", // Background image only for desktop
      backgroundSize: "cover", // Ensure the image covers the section
      backgroundPosition: isMobile ? "center" : "30% center", // Slightly shift image to the right
      backgroundRepeat: "no-repeat",
      minHeight: isMobile ? "auto" : "90vh", // Maintain height
      position: "relative",
      backgroundColor: isMobile ? "#f8f9fa" : "transparent", // Light background for smaller screens
    },
    contentBox: {
      backgroundColor: isMobile ? "transparent" : "rgba(255, 255, 255, 0.8)", // Slightly transparent for desktop
      color: "#262626", // Black text
      padding: "20px", // Consistent padding for both desktop and mobile
      borderRadius: "10px", // Rounded corners
      boxShadow: isMobile ? "none" : "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow only for desktop
      maxWidth: "400px",
      width: isMobile ? "90%" : "40%", // Adjust width for mobile and desktop
      textAlign: isMobile ? "center" : "left", // Align text left for desktop
      margin: isMobile ? "0 auto" : "0 0 0 20px", // Move to the left on desktop
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2rem",
      fontWeight: "bold",
      marginBottom: "10px", // Reduce bottom margin
      color: "#262626", // Ensure title is black
    },
    content: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.5",
      color: "#262626", // Ensure content text is black
    },
    imageSection: {
      display: isMobile ? "block" : "none", // Show image only on mobile
      width: "100%", // Full width
      height: "auto", // Maintain aspect ratio
      margin: "0", // Remove margins
    },
    image: {
      width: "100%", // Full width for responsive layout
      height: "auto", // Maintain aspect ratio
      display: "block",
    },
  };

  return (
    <section id="about" style={styles.aboutSection}>
      {/* Image Section for Small Screens */}
      {isMobile && (
        <div style={styles.imageSection}>
          <img
            src="/images/hermanos2.JPG" // Path to your image
            alt="Lobby Background"
            style={styles.image}
          />
        </div>
      )}

      {/* Text Section */}
      <div style={styles.contentBox}>
        <h2 style={styles.title}>Sobre Nosotros</h2>
        <p style={styles.content}>
        Fit Instinct fue fundado por Natan y Marcos, 
        dos hermanos que siempre estuvieron ligados a la actividad 
        física. Ambos representaron a El Salvador en atletismo. Su formación y experiencia en análisis de datos, 
        personal training y deporte de alto rendimiento fueron fundamentales para crear 
        una oferta tecnológica en la actividad física, única en el país. Nuestros programas, 
        software e instalaciones están diseñados para optimizar tu condición física, salud, 
        perder peso o mantenerte activo.
        </p>
      </div>
    </section>
  );
}

export default About;