import React, { useState } from "react";

function SpecialOfferJan2025() {
  const [hoveredButton, setHoveredButton] = useState(null);

  const styles = {
    offerSection: {
      minHeight: "500px",
      padding: "60px 20px",
      marginTop: "60px",
      backgroundColor: "#1466C3",
      color: "#FFFFFF",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: "20px",
    },
    description: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1.2rem",
      fontWeight: "500",
      lineHeight: "1.5",
      maxWidth: "800px",
      margin: "0 auto",
      marginBottom: "30px",
    },
    membership: {
      marginBottom: "40px",
      textAlign: "center",
    },
    membershipName: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    price: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1.3rem",
      fontWeight: "bold",
      color: "#FFFFFF",
    },
    button: {
      marginTop: "15px",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      backgroundColor: "#FFFFFF",
      color: "#1466C3",
      border: "none",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: "pointer",
      textTransform: "uppercase",
      transition: "transform 0.2s ease, background-color 0.3s ease",
    },
    buttonHovered: {
      transform: "scale(1.05)",
      backgroundColor: "#F8F8F8",
    },
  };

  const handleMouseEnter = (buttonId) => {
    setHoveredButton(buttonId);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  return (
    <div style={styles.offerSection}>
      <h2 style={styles.title}>¡20% de Descuento en Todas las Membresías!</h2>
      <p style={styles.description}>
        Este Año Nuevo, comienza tu camino hacia un estilo de vida saludable con FitInstinct. 
        Aprovecha un <strong>20% de descuento</strong> en nuestras membresías y haz que 2024 sea tu mejor año.
      </p>

      {/* Membership: Casual */}
      <div style={styles.membership}>
        <h3 style={styles.membershipName}>Membresía Casual</h3>
        <p style={styles.price}>Solo $68/mes (antes $85)</p>
        <button
          style={
            hoveredButton === "casual"
              ? { ...styles.button, ...styles.buttonHovered }
              : styles.button
          }
          onMouseEnter={() => handleMouseEnter("casual")}
          onMouseLeave={handleMouseLeave}
          onClick={() => (window.location.href = "https://pay.n1co.shop/pl/4pezQhVX1")}
        >
          Comprar Membresía Casual
        </button>
      </div>

      {/* Membership: Athlete */}
      <div style={styles.membership}>
        <h3 style={styles.membershipName}>Membresía Athlete</h3>
        <p style={styles.price}>Solo $96/mes (antes $120)</p>
        <button
          style={
            hoveredButton === "athlete"
              ? { ...styles.button, ...styles.buttonHovered }
              : styles.button
          }
          onMouseEnter={() => handleMouseEnter("athlete")}
          onMouseLeave={handleMouseLeave}
          onClick={() => (window.location.href = "https://pay.n1co.shop/pl/X5BvrsODV")}
        >
          Comprar Membresía Athlete
        </button>
      </div>

      {/* Membership: Elite */}
      <div style={styles.membership}>
        <h3 style={styles.membershipName}>Membresía Elite</h3>
        <p style={styles.price}>Solo $124/mes (antes $155)</p>
        <button
          style={
            hoveredButton === "elite"
              ? { ...styles.button, ...styles.buttonHovered }
              : styles.button
          }
          onMouseEnter={() => handleMouseEnter("elite")}
          onMouseLeave={handleMouseLeave}
          onClick={() => (window.location.href = "https://pay.n1co.shop/pl/xWBQZtjGQ")}
        >
          Comprar Membresía Elite
        </button>
      </div>
    </div>
  );
}

export default SpecialOfferJan2025;