import React, { useEffect, useState } from "react";
import NavbarComponent from "./NavbarComponent"; // Import the reusable Navbar component
import Footer from "./Footer"; // Import the Footer component

function InformacionEspecializado() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    section: {
      padding: "100px 20px 50px", // Adjusted padding to avoid overlap
      backgroundColor: "#FFFFFF", // White background
      color: "#262626", // Black text
    },
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "40px",
      maxWidth: "1200px",
      margin: "0 auto",
    },
    trainingContainer: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row", // Stack vertically on mobile
      alignItems: "center",
      gap: "20px",
    },
    textContainer: {
      flex: "1",
      padding: "20px",
      textAlign: isMobile ? "center" : "left", // Center text for mobile
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: "20px",
      textAlign: "center",
    },
    subtitle: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "1.8rem",
      fontWeight: "bold",
      marginBottom: "15px",
    },
    description: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.8",
    },
    imageContainer: {
      flex: "1",
      padding: "10px",
      order: isMobile ? "-1" : "0", // Stack image above text on mobile
    },
    image: {
      width: "100%",
      height: "auto",
      borderRadius: "10px",
    },
    buttonContainer: {
      textAlign: "center",
      marginBottom: "20px",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      backgroundColor: "#1466C3",
      color: "#ffffff",
      border: "none",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: "pointer",
      textTransform: "uppercase",
      transition: "all 0.3s ease-in-out",
    },
    buttonHover: {
      backgroundColor: "#0d4e91", // Slightly darker blue
      transform: "scale(1.05)", // Slight enlargement
    },
  };

  return (
    <div>
      {/* Navbar */}
      <NavbarComponent /> {/* Use the reusable NavbarComponent */}

      {/* Page Content */}
      <section id="entrenamiento-especializado" style={styles.section}>
        {/* Button at the top */}
        <div style={styles.container}>
          <h2 style={styles.title}>Entrenamiento Especializado</h2>
          <div style={styles.buttonContainer}>
            <button
              style={styles.button}
              onClick={() => window.open("https://wa.me/50374823777", "_blank")}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor =
                  styles.buttonHover.backgroundColor;
                e.currentTarget.style.transform = styles.buttonHover.transform;
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor =
                  styles.button.backgroundColor;
                e.currentTarget.style.transform = "scale(1)";
              }}
            >
              Pide tu Cotización Ya!
            </button>
          </div>
          {/* Personal Training */}
          <div style={styles.trainingContainer}>
            <div style={styles.textContainer}>
              <h3 style={styles.subtitle}>Entrenamiento Personalizado</h3>
              <p style={styles.description}>
                Diseñado para cualquier persona, el entrenamiento personal se
                puede realizar en nuestras instalaciones o en la comodidad de tu
                hogar. Adaptamos cada sesión a tus objetivos y necesidades para
                garantizar resultados efectivos.
              </p>
            </div>
            <div style={styles.imageContainer}>
              <img
                src="/images/personaltraining.jpg"
                alt="Entrenamiento Personalizado"
                style={styles.image}
              />
            </div>
          </div>

          {/* Corporate Training */}
          <div style={styles.trainingContainer}>
            <div style={styles.imageContainer}>
              <img
                src="/images/corporate.jpg"
                alt="Entrenamiento Corporativo"
                style={styles.image}
              />
            </div>
            <div style={styles.textContainer}>
              <h3 style={styles.subtitle}>Entrenamiento Corporativo</h3>
              <p style={styles.description}>
                Ideal para empresas, llevamos el entrenamiento directamente a los
                espacios de oficina. Promueve el bienestar de tus empleados con
                sesiones diseñadas para reducir el estrés, mejorar la energía y
                aumentar la productividad.
              </p>
            </div>
          </div>

          {/* High Performance Training */}
          <div style={styles.trainingContainer}>
            <div style={styles.textContainer}>
              <h3 style={styles.subtitle}>
                Equipos de Alto Rendimiento y Atletas
              </h3>
              <p style={styles.description}>
                Dirigido a equipos de alto rendimiento y atletas, ofrecemos
                entrenamientos en nuestras instalaciones o en los espacios de
                entrenamiento designados. Con un enfoque en datos y tecnología
                avanzada, ayudamos a maximizar el rendimiento y superar tus
                límites.
              </p>
            </div>
            <div style={styles.imageContainer}>
              <img
                src="/images/highperformance.jpg"
                alt="Entrenamiento de Alto Rendimiento"
                style={styles.image}
              />
            </div>
          </div>
        </div>
      </section>
      {/* Footer */}
      <Footer />
    </div>
  );
}

export default InformacionEspecializado;