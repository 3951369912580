import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function NuestraApp() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    appSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: isMobile ? "flex-start" : "center", // Center box vertically for desktop
      alignItems: isMobile ? "center" : "flex-start", // Align box to the left on desktop
      padding: "0",
      backgroundImage: isMobile ? "none" : "url('/images/pantallapp2.png')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      minHeight: "100vh",
      position: "relative",
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center", // Center text vertically inside the box
      alignItems: "center", // Center text horizontally inside the box
      backgroundColor: isMobile ? "transparent" : "rgba(0, 0, 0, 0.6)", // Black background for desktop
      color: isMobile ? "#262626" : "#ffffff", // Black text for mobile, white for desktop
      padding: "20px",
      borderRadius: isMobile ? "0" : "10px",
      boxShadow: isMobile ? "none" : "0 4px 8px rgba(0, 0, 0, 0.1)", // Add shadow for desktop
      maxWidth: "500px",
      textAlign: "center",
      margin: isMobile ? "10px auto" : "0 0 0 40px", // Center for mobile, left-align for desktop
      height: "auto",
    },
    imageContainer: {
      width: "100%",
      height: isMobile ? "60vh" : "auto", // Fixed height for mobile
      backgroundImage: isMobile ? "url('/images/pantallapp2.png')" : "none",
      backgroundSize: "cover",
      backgroundPosition: "right center", // Focus image on the right side for mobile
      backgroundRepeat: "no-repeat",
    },
    title: {
      fontFamily: "Gendy, sans-serif",
      fontSize: "2rem",
      fontWeight: "bold",
      marginBottom: "10px",
    },
    description: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.8",
      marginBottom: "10px",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
      backgroundColor: "#1466C3",
      color: "#ffffff",
      border: "none",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: "pointer",
      textTransform: "uppercase",
      transition: "all 0.3s ease-in-out",
    },
    buttonHover: {
      backgroundColor: "#0d4e91",
      transform: "scale(1.05)",
    },
  };

  const handleMouseOver = (e) => {
    e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
    e.currentTarget.style.transform = styles.buttonHover.transform;
  };

  const handleMouseOut = (e) => {
    e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
    e.currentTarget.style.transform = "scale(1)";
  };

  return (
    <section id="nuestraapp" style={styles.appSection}>
      {/* Mobile Image Section */}
      {isMobile && <div style={styles.imageContainer}></div>}

      {/* Content Section */}
      <div style={styles.contentContainer}>
        <h2 style={styles.title}>Nuestros Sistemas</h2>
        <p style={styles.description}>
          Descubre cómo nuestros sistemas impulsados por Uptivo transforman tu entrenamiento. 
          Reserva clases fácilmente, recibe notificaciones en tiempo real y monitorea tu progreso con datos avanzados. 
          Todo disponible las 24 horas para adaptarse a tu estilo de vida.
        </p>
        <button
          style={styles.button}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={() => navigate("/sistemas")}
        >
          Descubre Más
        </button>
      </div>
    </section>
  );
}

export default NuestraApp;