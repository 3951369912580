import React from "react";

function SpecialOfferJan2025Explanation() {
  const styles = {
    offer: {
      backgroundColor: "#FFFFFF", // White background
      color: "#000000", // Black text
      padding: "20px",
      position: "sticky",
      top: "60px", // Positioned below the navbar
      zIndex: 999,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontFamily: "Montserrat, sans-serif",
      fontSize: "1rem",
      fontWeight: "500",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for visibility
    },
    text: {
      margin: 0,
      fontWeight: "bold",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: "bold",
      fontSize: "1rem",
      backgroundColor: "#1466C3",
      color: "#FFFFFF",
      border: "none",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "background-color 0.3s ease-in-out",
    },
    buttonHover: {
      backgroundColor: "#0D4E91",
    },
  };

  return (
    <div style={styles.offer}>
      <p style={styles.text}>
        ¡20% de Descuento en Todas las Membresías: <strong>Casual</strong>, <strong>Athlete</strong>, y <strong>Elite</strong>! 
        Empieza el Año Nuevo con FitInstinct y ahorra.
      </p>
      <button
        style={styles.button}
        onMouseOver={(e) =>
          (e.currentTarget.style.backgroundColor =
            styles.buttonHover.backgroundColor)
        }
        onMouseOut={(e) =>
          (e.currentTarget.style.backgroundColor = styles.button.backgroundColor)
        }
        onClick={() => {
          window.location.href = "/memberships";
        }}
      >
        Más Información
      </button>
    </div>
  );
}

export default SpecialOfferJan2025Explanation;